<template>
  <div class="page">
    <!--pages/mytickets/mytickets.wxml-->
    <!--<span>我的门票</span>-->
    <div class="head flexcenter">
      <div :class="['item', {active: tab == 1}]" @click="toggleTab(1)">
        待付款
      </div>
      <div :class="['item', {active: tab == 2}]" @click="toggleTab(2)">
        待发货
      </div>
      <div :class="['item', {active: tab == 3}]" @click="toggleTab(3)">
        待收货
      </div>
      <div :class="['item', {active: tab == 4}]" @click="toggleTab(4)">
        已完成
      </div>
	  <div :class="['item', {active: tab == 5}]" @click="toggleTab(5)">
	    已取消
	  </div>
	  <div :class="['item', {active: tab == 0}]" @click="toggleTab(0)">
	    全部
	  </div>
    </div>
	
    <div class="list" v-if="orders.length>0">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
	  <div class="item" v-for="(order, idx) in orders" :key="order.order_id" @click="goDetails(order.order_id)">
        <div class="status-box flexcenter">
		  <div class="left">下单时间：{{order.add_time}}</div>
		  <div class="right">{{order.small_status_text}}</div>
		</div>
      
        <div class="detail-box" v-for="(item, idx) in order.goods" :key="item.goods_id">
            <div class="left">
              <img class="full-img" mode="widthFix" :src="item.default_image">
            </div>
            <div class="right">
				<div class="title-box">
					<div class="title ellipsis2">{{item.goods_name}}</div>
					<div class="price">￥{{item.order_price}}</div>
				</div>
				<div class="desc-box flexcenter">
					<div v-if="item.sku_goods_name != ''" class="sku">规格：{{item.sku_goods_name}}</div>
					<div v-else class="nosku">规格：--</div>
					<div>x{{item.goods_number}}</div>
				</div>
			</div>
        </div>
		
		<div class="price-box">
		  <span class="consignee" v-if="order.consignee">{{order.consignee}}</span>
		  <span class="yh">共 {{order.jianshu}} 件商品,总金额</span><span class="price">￥{{order.order_amount}}</span>
		</div>
		<div class="action-box flexcenter" v-if="order.small_status=='1'">
		  <div></div>
		  <div class="btn-box">
			<div class="cancel" @click.stop="cancelOrder(order.order_id)">取消订单</div>
			<div class="gopay" @click.stop="goSelPayment(order.order_id)">继续支付</div>
		  </div>
		</div>
		<div v-else class="no-action-box"></div>
      </div>
      </van-list>
    </div>
	<div class="no-data-box" v-else>
	  暂无数据
	</div>
	
	<van-popup round v-model="showSelPayment" bind:close="closeSelPayment">
	  <div class="selPaymentListBox">
	    <div class="paymentItem" @click="onSelPayment(index)" v-for="(item,index) in paymentList">
	      <div v-if="currentPayment.id==item.id" class="checkbox iconfont iconxuanzhong"></div>
	      <div v-else class="checkbox iconfont iconweixuanzhong"></div>
	      <div class="payment">
	        <div class="icon"><img :src="item.icon"></img></div>
	        <div class="name">{{item.name}}</div>
	      </div>
	    </div>
	  </div>
	</van-popup>
	
  </div>
</template>

<script>
  import Vue from 'vue';
  import { List } from 'vant';
	
  Vue.use(List);
  export default {
    name: 'myorders',
    data() {
      return {
        tab: 0,
		page: 1,
		totalPage: 0,
		smallStatus:0,
		orders: [],
		loading:false,
		showSelPayment:false, //显示支付方式选择弹框
		goPayOid:'',
		paymentList:[], //可用支付方式
		currentPayment:[],
		loading: false,
		finished: false,
      }
    },
    created() {
		if(typeof this.$route.query.type == "undefined")
		{
		  this.smallStatus ="0";
		  this.tab = "0";
		  this.orders = [];
		}
		else
		{
		  let type = this.$route.query.type;
		  let smallStatus = 0;
		  let tab;
		  if(type.length>0)
		  {
			if(type=='1'){ smallStatus = 1;tab=1;}
			if(type=='2'){ smallStatus = 2;tab=2;}
			if(type=='3'){ smallStatus = 3;tab=3;}
			if(type=='4'){ smallStatus = 4;tab=4;}
			if(type=='5'){ smallStatus = 5;tab=5;}
			this.smallStatus = smallStatus;
			this.tab = tab
			this.orders = [];
		  }
		}
		
        this.getOrderList(1,true)
    },
    methods: {
	  toggleTab(tab){
	      let idx = tab
	      let smallStatus = 0;
	      if(idx==0){smallStatus = 0;}
	      if(idx==1){smallStatus = 1;}
	      if(idx==2){smallStatus = 2;}
	      if(idx==3){smallStatus = 3;}
	      if(idx==4){smallStatus = 4;}
	      if(idx==5){smallStatus = 5;}
	      this.tab = +idx;
	      this.smallStatus = smallStatus;
	  
	      this.getOrderList(1,true);
	    },
      //获取商品信息数据
      getOrderList(pageNo, override){
        let that = this;
        this.post(
          this.API.myOrderList,
          {page:pageNo,small_status:that.smallStatus}
        ).then(response => {
			console.log(response);
          let orders = response.data.order.data;
          this.paymentList =response.data.payments
          this.page = response.data.order.current_page     //当前的页号
          this.totalPage = response.data.order.last_page  //总页数
          this.orders = override ? orders : that.orders.concat(orders)
		  this.loading=false;
        })
      },
	  /**订单详情 */
	  goDetails(oid){
		  this.$router.push({
		    name: 'orderdetails',
		    query: {
		      oid:oid,
		    }
		  })
	  },
	  /**
		* 取消订单 
		*/
	  cancelOrder:function(oid){
	    if(oid==''||typeof(oid)=="undefined")
	    {
		  this.$toast("缺少订单参数");
		  return;
	    }
	    let that = this;
	    this.$dialog.confirm({ title: '提示',message: '确认取消此订单？'})
			.then(res => {
				that.post(that.API.cancelOrder,{oid:oid}).then(
					response => {
					if(res.data.err=='1'){
					  that.$toast(res.data.msg);
					}else{
					  that.$toast(res.data.msg);
					  that.getOrderList(1,true);
					}
				})
			})
	  },
      /**选择支付方式 */
      goSelPayment:function(oid){
        this.showSelPayment = true
        this.goPayOid = oid
      },
      closeSelPayment:function(){
        this.showSelPayment=false
      },
      onSelPayment:function(idx){
        this.currentPayment = this.paymentList[idx]
        this.showSelPayment = false

        this.goPay()
      },
      /**
       * 继续支付
       */
      goPay:function(e){
        let oid = this.goPayOid;
        if(oid==''||typeof(oid)=="undefined")
        {
          this.$toast("缺少订单参数");
          return;
        }
        let that = this;

        // 查看是否授权
        this.post(this.API.goPayOrder, {oid:oid, payment:that.data.currentPayment.id})
			.then(response => {
			  if(response.data.err=='1')
			  {
				this.$toast(response.data.msg);
				return false;
			  }
			  else
			  {
				if(response.data.paydata.data.type=='local')
				{
				  this.$toast('订单支付成功!');
				  that.getOrderList(1,true);
				  return ;
				}
				
				let trans_no = response.data.paydata.data.trans_no;
				// TODO: 需要请求后端接口获取订单号
				upsdk.pay({
				  tn: response.data.paydata.data.result.tn,
				  success: function(res){
					console.log(res);
				    that.checkPay(trans_no)
				  },
				  fail: function(err){
				    // 支付失败, err.msg 是失败原因描述, 比如TN号不合法, 或者用户取消了交易 等等。
					console.log(err);
					that.cancelPay(err)
				  }
				});
			}
        })
		
      },
      checkPay:function(trans_no) {
		let that = this;
        this.post(this.API.checkPay,{trans_no:trans_no})
			.then(response => {
			  if(response.data.err=='0') {
				that.$toast('支付成功');
			  }else{
				that.$toast(response.data.msg)
			  }
			  that.getOrderList(1,true);
        })
      },
	  cancelPay:function(res) {
		  console.log(res);
		  this.$toast("您取消了支付"+res.msg);
	  },
	  onLoad(){
	  		 console.log("触底下拉");
	  		 console.log(this.page);
	  		 console.log(this.totalPage);
	  		if (this.page < this.totalPage) {
	  		    this.getOrderList(parseInt(this.page) + 1,false)
	  		}else if(this.page>=this.totalPage){
	  			if(this.totalPage>0){
	  				this.$toast("这就是全部")
	  			}
	  			this.loading=false;
	  		}
	  }
	  
    }
  };
</script>

<style lang="scss" scoped>
  .page {
      background-color: #F5F5F5;
      min-height: 100%;
      font-size: 32px;
      line-height: 1.6;
      font-family: "PingFang SC";
  }
  
  .head {
      justify-content: space-between;
      height: 88px;
      background-color: #FFFFFF;
      font-size: 24px;
      color: #777777;
  }
  .head .item {
      width: 137px;
      height: 88px;
      line-height: 88px;
      text-align: center;
  }
  .head .active {
      position: relative;
      color: #191919;
      font-weight: bold;
  }
  .head .active:after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -48px;
      width: 96px;
      height: 8px;
      border-radius: 50px 50px 0 0;
      background-color: #F8D414;
  }
  
  .list {
      padding: 24px;
  }
  .item {
      margin-bottom: 24px;
      padding: 0 24px;
      border-radius: 8px;
      background-color: #FFFFFF;
  }
  
  .item .status-box {
      justify-content: space-between;
      height: 85px;
      border-bottom:2px solid #eeeeee;
  }
  .item .status-box .left {
      font-size: 24px;
      color: #999999;
  }
  .item .status-box .right {
      font-size: 28px;
      color: #F8D414;
  }
  
  .item .detail-box {
      display: flex;
      justify-content: space-between;
      padding: 18px 0px;
  }
  .item .detail-box .left {
      margin-right: 20px;
      width: 140px;
	  flex: 1;
  }
  .item .detail-box .left .order-img {
      max-height: 128px;
  }
  .item .detail-box .right {
      flex: 2;
  }
  .item .detail-box .right .title-box {
      display: flex;
      justify-content: space-between;
      font-size: 28px;
      color: #191919;
  }
  .item .detail-box .right .title-box .title {
      width: 346px;
  }
  .item .detail-box .right .desc-box {
      justify-content: space-between;
      font-size: 24px;
      margin: 16px 0;
      color: #777777;
  }
  .item .price-box {
      border-top:2px solid #eeeeee;
      padding-top:6px;
      text-align: right;
      font-size: 24px;
      color: #777777;
  }
  .item .price-box .price{
      font-size: 28px;
      color: #ff0000;
  }
  .item .price-box .consignee {
      float: left;
  }
  .item .action-box {
      justify-content: space-between;
      height: 104px;
  }
  .item .no-action-box {
      height: 20px;
  }
  .item .action-box .cancel {
      width: 160px;
      height: 56px;
      line-height: 56px;
      text-align: center;
      border: 2px solid #999999;
      border-radius: 28px;
      font-size: 28px;
      color: #777777;
      display: inline-block;
  }
  .item .action-box .gopay {
      width: 160px;
      height: 56px;
      line-height: 56px;
      text-align: center;
      border: 2px solid #FCC601;
      background:#FCC601;
      border-radius: 28px;
      font-size: 28px;
      color: #FFFFFF;
      display: inline-block;
      margin-left: 20px;
  }
  .no-data-box {
      text-align: center;
      width: 100%;
      display: block;
      padding-top: 100px;
      font-size: 50px;
      color: #cccc;
  }

</style>
